/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Produkty značky Trolli "}>
        <Column className="css-8fxvv7 --style4 --full --parallax" anim={""} name={"[[UNIsecname1]]"} animS={"3"} border={""} fullscreen={true} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/c3dfc6fefb764800b4c59c8a0921c2fd_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/c3dfc6fefb764800b4c59c8a0921c2fd_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/c3dfc6fefb764800b4c59c8a0921c2fd_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/c3dfc6fefb764800b4c59c8a0921c2fd_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/c3dfc6fefb764800b4c59c8a0921c2fd_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/c3dfc6fefb764800b4c59c8a0921c2fd_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/c3dfc6fefb764800b4c59c8a0921c2fd_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/c3dfc6fefb764800b4c59c8a0921c2fd_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1390}} anim={null} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mb--20 pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":1425}} anim={"2"} animS={"5"}>
            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mb--20 pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":1425}} anim={"2"} animS={"5"}>
              
              <Title className="title-box fs--102 w--500 title-box--invert lh--1 mt--10" style={{"maxWidth":1106}} content={"&nbsp;"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"[[UNIgenericsection2]]"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" style={{"backgroundColor":"var(--color-blend--95)"}} name={"[[UNIgenericsecname3]]"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box" content={"Trolli produkty&nbsp;"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}